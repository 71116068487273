import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { PILLS_COLUMN_CONFIG } from "./PillsColumn_config";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { GridPills } from "../../../../../../../components/widgets/gridPills/GridPills";

interface IPillsColumnBuilder {}

export class PillsColumnBuilder extends BaseColumnBuilder implements IPillsColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...PILLS_COLUMN_CONFIG(columnOptions) });
  }

  withCellRenderer = (key: string) => {
    this.setColumnOptions({
      cellRenderer: e => <GridPills text={e.data[key]} />
    });
    return this;
  };

  withJSONCellRenderer = (key: string) => {
    this.setColumnOptions({
      cellRenderer: e => {
        let js = e.data[key];
        return <GridPills text={js?.map(e => e.name).join("|") ?? ""} />;
      }
    }).withJSONValueGetter(key); // Goes together to ensure value is a joined string in the Pills format (needed for filtering)

    return this;
  };
  withJSONValueGetter = (key: string) => {
    this.setColumnOptions({
      valueGetter: e => {
        let js = e.data[key];
        return js?.map(e => e.name).join("|") ?? "";
      }
    });
    return this;
  };

  setEditableOnDoubleClick = (callback: (item: any) => void) => {
    this.setColumnOptions({ onCellDoubleClicked: e => callback(e.data) });
    return this;
  };
}
