import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IPulseDetailCellRendererProps } from "./PulseDetailCellRenderer_view";
import PulsesApi, { PulsesApi as IPulsesApi } from "../../../../../../../services/api/v2/pulses/Pulses.api";

export default class PulseDetailCellRendererModel {
  organisationId: number;
  projectId: number;
  pulseId: number;
  @observable isLoading: boolean = true;
  pulseProvider: IPulsesApi;
  cellRendererProps: IPulseDetailCellRendererProps;
  httpProgress: IProgressIndicatorModel;
  @observable audiences: FP.Entities.IAudienceSummary[];
  refreshParentDataHandler: any;

  constructor(
    organisationId: number,
    projectId: number,
    pulseId: number,
    cellRendererProps: IPulseDetailCellRendererProps,
    refreshParentDataHandler: any
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.pulseId = pulseId;
    this.cellRendererProps = cellRendererProps;
    this.pulseProvider = PulsesApi;
    this.refreshParentDataHandler = refreshParentDataHandler;
    this.httpProgress = ProgressIndicatorModel;
  }

  closeExpandedRow = () => {
    this.cellRendererProps.node.parent.setExpanded(false);
    this.cellRendererProps.api.setGridOption("context", {
      ...this.cellRendererProps.api.getGridOption("context"),
      selectedDetail: null
    });
    this.cellRendererProps.api.onGroupExpandedOrCollapsed();
    this.cellRendererProps.api.refreshHeader();
  };

  refreshData = async () => {
    await this.loadAudiences();
    await this.refreshParentDataHandler();
  };

  loadAudiences = async () => {
    this.httpProgress.showOverlay();
    const res = await this.pulseProvider.getAudienceStakeholdersNestedGridData(this.organisationId, this.pulseId);
    if (!res || res.isError) return;

    this.setAudiences(res.payload);
    this.setIsLoading(false);
    this.httpProgress.hideOverlay();
  };

  @action
  setAudiences = (audiences: FP.Entities.IAudienceSummary[]) => {
    this.audiences = audiences;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
