import React, { useState } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import { getAudienceStakeholderTagFormFields } from "./AudienceStakeholderTagsSidebar_formFields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ManageAudienceStakeholderTagFormProps {
  organisationId: number;
  projectId: number;
  audienceStakeholder: FP.Entities.IAudienceStakeholder;
  refreshHandler: () => void;
}

class ManageAudienceStakeholderTagFormModel {
  formModel: SingleFormModel;

  constructor(
    organisationId: number,
    projectId: number,
    audienceStakeholder: FP.Entities.IAudienceStakeholder,
    refreshHandler: () => void
  ) {
    this.formModel = new SingleFormModel();

    const tagsProvider = TagsApi;
    let formFields = getAudienceStakeholderTagFormFields(
      tagsProvider,
      organisationId,
      projectId,
      audienceStakeholder,
      refreshHandler
    );
    this.formModel.formFields = formFields;
  }
}

export const ManageAudienceStakeholderTagForm: React.FC<ManageAudienceStakeholderTagFormProps> = props => {
  const { audienceStakeholder, projectId, organisationId, refreshHandler } = props;
  const modalService = useModalContext();

  let [model] = useState(
    () => new ManageAudienceStakeholderTagFormModel(organisationId, projectId, audienceStakeholder, refreshHandler)
  );

  return (
    <div>
      <SingleForm model={model.formModel} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
