import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "./_phases-grid.scss";
import { WrapperPhasesGridWithDataModel } from "./WrapperPhasesGridWithData_model";
import { useFlightPathUser } from "../../../../../../auth_insight";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Animations } from "../../../../../../util/Animations";
import { PhasesGrid } from "../PhasesGridView_view";
import { IF } from "../../../../../../../components/hoc/If";

export interface WrapperPhaseGridPropsWithData {
  showAudiences?: boolean;
}

const WrapperPhaseGridWithData: React.FunctionComponent<WrapperPhaseGridPropsWithData> = observer(props => {
  const authUser = useFlightPathUser();
  const { showAudiences } = props;
  const { organisationId, projectId } = useParams<{ organisationId: string; projectId: string }>();
  const [model] = useState(() => new WrapperPhasesGridWithDataModel(+organisationId, +projectId, authUser));

  useEffect(() => {
    model.onMount();
  }, [model]);
  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const { projectPhases, project } = model;

  return (
    <div
      className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4 phases-settings insight-tab-content__space-filler`}
      id="EditProjectPhasesPage"
      style={{ height: "100%" }}
    >
      <IF condition={!model.isLoading}>
        <div className="row px-4">
          <h1>
            {project?.name} | {moment(project?.startDate).format("L")} - {moment(project?.actualEndDate).format("L")}
          </h1>
        </div>
        <PhasesGrid
          data={projectPhases}
          onFieldUpdate={model.loadProjectPhases}
          refreshDataHandler={model.loadProjectPhases}
          showAudiences={showAudiences}
        />
      </IF>
    </div>
  );
});

export { WrapperPhaseGridWithData };
