import moment from "moment";
import { FormRequiredFieldIndicator } from "../../../../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { ErrorMessage } from "../../../../../../../components/ui/ErrorMessage";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { FORM_COL } from "../../../../../../../constants";
import { INIT_DATEPICKER } from "../../../../../../forms/controls/datePicker/DatePicker_model";
import { ITextFieldModel } from "../../../../../../forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../../forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../../../forms/formField/IFormField";
import { Validations } from "../../../../../../forms/helpers/Validations";
import I18n from "../../../../../../localization/I18n";
import { IDatePickerModel } from "../../../../../../forms/controls/datePicker/IDatePickerModel";
import { minimumDate } from "../../../../../../../enums";
import { IRTEditorModel } from "../../../../../../forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../../../forms/controls/rteditor/RTEditor_model";
import { generateFormFieldsFromJson } from "../../../../../../forms/helpers/FormFieldMappers";

export const getCreatePulseFormFields = (phase?: FP.Entities.IPhase): IFormFieldModel<any, any>[] => {
  const phaseStartDate = moment(phase.startDate);
  const projectStartDate = moment(phase.project.startDate);
  const projectEndDate = moment(phase.project.actualEndDate);

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: "Enter Pulse title",
    label: (
      <label htmlFor="name">
        {I18n.t("forms.pulseName")} <FormRequiredFieldIndicator />
      </label>
    ),
    value: "",
    fieldClassName: FORM_COL.HALF_WIDTH,
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.setErrorMessage(<ErrorMessage>{I18n.t("validations.pulseName")}</ErrorMessage>);
        res = false;
      }
      return res;
    }
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.overview")}</label>,
    placeholder: I18n.t("placeholders.overview"),
    fieldClassName: FORM_COL.HALF_WIDTH,
    value: "",
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.pulseOverviewHint")}</p>,
    extractValue: function () {
      return this.value;
    }
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar,
      showClearDate: true,
      datePickerProps: {
        ...INIT_DATEPICKER.componentProps.datePickerProps,
        defaultDate: projectStartDate,
        isOutsideRange: day => {
          if (phaseStartDate > moment(minimumDate)) {
            return day < phaseStartDate; // need to add check for day to be before next phase startdate
          }
          return day < projectStartDate || day >= projectEndDate;
        },
        numberOfMonths: 1,
        displayFormat: "",
        initialVisibleMonth: () => projectStartDate
      }
    },
    onValueChange: (value, model) => {
      const endDateChannelValue = model.channels.endDate.value;
      (model as any).subscribers
        .find(e => e.key === "endDate")
        .setDatepickerProps({
          ...INIT_DATEPICKER.componentProps.datePickerProps,
          isOutsideRange: day => {
            return (
              (endDateChannelValue > moment(minimumDate) && day >= moment(endDateChannelValue)) || day < phaseStartDate
            );
          },
          initialVisibleMonth: () => (endDateChannelValue != null ? endDateChannelValue : projectEndDate)
        });
    },
    subscribeTo: ["endDate"],
    key: "startDate",
    label: (
      <label htmlFor="startDate">
        {I18n.t("forms.startDate")} <FormRequiredFieldIndicator />
      </label>
    ),
    placeholder: "dd/mm/yyyy",
    fieldClassName: FORM_COL.HALF_WIDTH,
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (moment(endDate.value) <= moment(this.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: phaseStartDate && phaseStartDate > moment(minimumDate) ? phaseStartDate : null,
    extractValue: function () {
      return this.value ?? moment(minimumDate);
    },
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "endDate") {
        const sd = field.value;
        self.setDatepickerProps({
          ...INIT_DATEPICKER.componentProps.datePickerProps,
          isOutsideRange: day => {
            return (sd > moment(minimumDate) && day >= moment(sd)) || day < phaseStartDate;
          },
          initialVisibleMonth: () => (sd != null ? sd : projectEndDate)
        });
      }
    }
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "endDate",
    label: (
      <label htmlFor="endDate">
        {I18n.t("forms.endDate")} <FormRequiredFieldIndicator />
      </label>
    ),
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.HALF_WIDTH,
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar,
      showClearDate: true,
      datePickerProps: {
        ...INIT_DATEPICKER.componentProps.datePickerProps,
        defaultDate: projectEndDate,
        isOutsideRange: day => {
          if (phaseStartDate > moment(minimumDate)) {
            return day < phaseStartDate; // need to add check for day to be before next phase startdate
          }
          return day < projectStartDate || day >= projectEndDate || day < startDate.value;
        },
        numberOfMonths: 1,
        initialVisibleMonth: () => projectEndDate
      }
    },
    onValueChange: (value, model) => {
      const startDateChannelValue = model.channels.startDate.value;
      (model as any).subscribers
        .find(e => e.key === "startDate")
        .setDatepickerProps({
          ...INIT_DATEPICKER.componentProps.datePickerProps,
          isOutsideRange: day => {
            return (
              (startDateChannelValue > moment(minimumDate) && day <= moment(startDateChannelValue)) ||
              day <= phaseStartDate
            );
          },
          initialVisibleMonth: () => (startDateChannelValue != null ? startDateChannelValue : projectStartDate)
        });
    },
    value: null,
    subscribeTo: ["startDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        self.setDatepickerProps({
          ...INIT_DATEPICKER.componentProps.datePickerProps,
          isOutsideRange: day => {
            return (sd > moment(minimumDate) && day <= moment(sd)) || day <= phaseStartDate;
          },
          initialVisibleMonth: () => (sd != null ? sd : projectStartDate)
        });
      }
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (startDate >= this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      return this.value ?? moment(minimumDate);
    }
  };

  const fields = [];

  fields.push(name);
  if (!!phaseStartDate && phaseStartDate > moment(minimumDate)) {
    fields.push(startDate);
  }
  fields.push(description);
  if (startDate.value) {
    fields.push(endDate);
  }

  const models = generateFormFieldsFromJson(fields);
  return models;
};
