import { action, makeObservable, observable } from "mobx";
import PulsesApi, { PulsesApi as IPulsesApi } from "../../../../../../../services/api/v2/pulses/Pulses.api";
import { SingleFormModel } from "../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { getCreatePulseFormFields } from "./PhaseDetailCellRenderer_formFields";
import { IUiAction, UiActionRenderers } from "../../../../../../uiAction/IUiAction";
import I18n from "../../../../../../localization/I18n";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IPhaseDetailCellRendererProps } from "./PhaseDetailCellRenderer_view";

export default class PhaseDetailCellRendererModel {
  organisationId: number;
  projectId: number;
  phaseId: number;
  @observable isLoading: boolean = true;
  pulseProvider: IPulsesApi;
  formModel: SingleFormModel;
  cellRendererProps: IPhaseDetailCellRendererProps;
  httpProgress: IProgressIndicatorModel;
  @observable pulses: FP.Entities.IPulse[];

  constructor(
    organisationId: number,
    projectId: number,
    phaseId: number,
    cellRendererProps: IPhaseDetailCellRendererProps
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.phaseId = phaseId;
    this.cellRendererProps = cellRendererProps;
    this.pulseProvider = PulsesApi;
    this.httpProgress = ProgressIndicatorModel;
    this.formModel = new SingleFormModel();
    this.initForm();
  }

  initForm = () => {
    let formFields = getCreatePulseFormFields(this.cellRendererProps.data);
    this.formModel.formFields = formFields;

    let actions: IUiAction<any>[] = [
      {
        id: "action2",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.closeExpandedRow();
        },
        componentProps: {
          className: "ml-auto",
          type: ButtonTypes.OUTLINE_PRIMARY
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action1",
        label: I18n.t("phrases.add"),
        onAction: async ev => {
          let res = await this.formModel.submit();
          if (!res) return;
          await this.addPulse(res);
          this.closeExpandedRow();
        },
        componentProps: {
          className: "ml-3"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    this.formModel.actions = actions;
  };

  closeExpandedRow = () => {
    this.cellRendererProps.node.parent.setExpanded(false);
    this.cellRendererProps.api.setGridOption("context", {
      ...this.cellRendererProps.api.getGridOption("context"),
      selectedDetail: null
    });
    this.cellRendererProps.api.onGroupExpandedOrCollapsed();
    this.cellRendererProps.api.refreshHeader();
  };

  addPulse = async data => {
    this.httpProgress.showOverlay();
    data.projectId = this.projectId;
    await this.pulseProvider.createPhasePulse(this.organisationId, this.phaseId, data);
    this.httpProgress.hideOverlay();
  };

  loadPulses = async () => {
    this.httpProgress.showOverlay();

    const res = await this.pulseProvider.getGridData(this.organisationId, this.phaseId);
    if (!res || res.isError) return;

    this.setPulses(res.payload);
    this.httpProgress.hideOverlay();
  };

  @action
  setPulses = (pulses: FP.Entities.IPulse[]) => {
    this.pulses = pulses;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
