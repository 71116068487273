import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import Http from "../Http";
import { IFilteredResponse } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { PulseField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";

export class PulsesApi extends FilteredOrganisationApiModel<FP.Entities.IPulse> {
  controller: string = "pulses";

  constructor(http: AxiosInstance) {
    super(http, "pulses");
  }

  getByPhaseIdAndName = async (
    organisationId: number,
    phaseId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<any>> => {
    const url = `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/name/${name}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  createPhasePulse = async (organisationId: number, phaseId: number, data: any, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}`;
    let res = await this.http.post(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getGridData = async (
    organisationId: number,
    phaseId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    const url = `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/grid-data`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getAudienceStakeholdersNestedGridData = async (
    organisationId: number,
    pulseId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAudienceSummary[]>> => {
    const url = `${this.url}/${organisationId}/phases/0/${this.controller}/${pulseId}/nested-audience-grid-data`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getByIdDetailed = async (
    organisationId: number,
    phaseId: number,
    pulseId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    const url = `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/${pulseId}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  updateField = async (
    organisationId: number,
    phaseId: number = null,
    pulseId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    pulseField: PulseField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/${pulseId}/${pulseField}`,
      data,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, phaseId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  deleteGridField = async (
    organisationId: number,
    phaseId: number,
    pulseId: number,
    pulseField: PulseField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/phases/${phaseId}/${this.controller}/${pulseId}/${pulseField}`,
      config
    );
    return res.data;
  };
}

let instance = new PulsesApi(Http);

export default instance;
