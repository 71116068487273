import { ReactNode, SyntheticEvent } from "react";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { MASTER_DETAIL_ROW_COLUMN_CONFIG } from "./MasterDetailRowColumn_config";
import { ICellRendererParams } from "ag-grid-community";

export class MasterDetailRowColumnBuilder extends BaseColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...MASTER_DETAIL_ROW_COLUMN_CONFIG(), ...columnOptions });
  }

  withCustomActionsCellRenderer = (
    field: string,
    cellContent: (isExp: boolean, c: string, onclick: (ev: SyntheticEvent) => void) => ReactNode
  ) => {
    this.setColumnOptions({
      cellRenderer: params => {
        return this.makeMasterCellRenderer({
          params,
          col: field,
          cellContent: cellContent
        });
      }
    });

    return this;
  };

  makeMasterCellRenderer = ({
    params,
    col,
    cellContent
  }: {
    params: ICellRendererParams;
    col: string;
    cellContent: (isExp: boolean, c: string, onclick: (ev: SyntheticEvent) => void) => ReactNode;
  }) => {
    const { selectedDetail } = params.api.getGridOption("context");
    const isExpanded = params.node.expanded && selectedDetail?.some(e => e.field === col && e.rowId === params.data.id);

    const btnClass = `row-${params.data.id}`;

    const handleBtnClick = event => {
      this.openDetail(params, col);
    };

    return (
      <div className="master-container d-flex justify-content-center">
        {cellContent(isExpanded, btnClass, handleBtnClick)}
      </div>
    );
  };

  openDetail = (params, column) => {
    const context = params.api.getGridOption("context");
    context.selectedDetail = context.selectedDetail || [];

    if (context?.selectedDetail?.some(e => e.field === column && e.rowId === params.data.id) && params.node.expanded) {
      params.node.setExpanded(false);
      context.selectedDetail = context.selectedDetail?.filter(e => e.rowId !== params.data.id);
      params.api.setGridOption("context", { ...context });
      params.api.onGroupExpandedOrCollapsed();
      params.api.refreshHeader();
      return;
    }

    context.selectedDetail = context.selectedDetail.filter(e => e.rowId !== params.data.id);
    context.selectedDetail && context.selectedDetail.push({ field: column, rowId: params.data.id });

    params.api.setGridOption("context", { ...context });

    params.api.setGridOption("getRowClass", params => {
      if (params.node.expanded) {
        return "pulse-detail-row-master--expanded";
      }
      return undefined;
    });

    params.node.setExpanded(true);

    params.api.onGroupExpandedOrCollapsed();
    params.api.refreshHeader();

    if ((params.node as any).detailNode) {
      params.api.redrawRows({ rowNodes: [(params.node as any).detailNode] });
    }
  };
}
