import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { AudienceStakeholderTagsSidebar } from "./AudienceStakeholderTagsSidebar_view";

export const AUDIENCE_STAKEHOLDER_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.IAudienceStakeholder
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.tags")} />,
    content: <AudienceStakeholderTagsSidebar projectId={projectId} itemId={item.id} />
  };
};
