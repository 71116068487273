export enum ImpactGroupField {
  NAME = 1,
  DESCRIPTION = 2
}

export enum ProcessHierarchField {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
  LEVEL_5 = 5,
  UNIQUE_IDENTIFIER = 6
}

export enum ImpactField {
  NAME = 1,
  DESCRIPTION = 2,
  OWNER = 3,
  START_DATE = 4,
  END_DATE = 5,
  PROGRESS_STATUS = 6,
  IMPACT_TYPE = 7,
  NUMBER_IMPACTED = 8,
  IMPACT_LEVEL = 9,
  MITIGATION_CONFIDENCE = 10
}

export enum PhaseField {
  NAME = 1,
  DESCRIPTION = 2,
  START_DATE = 3,
  END_DATE = 4,
  PROGRESS_STATUS = 5,
  awareness = 6,
  understanding = 7,
  commitment = 8,
  capability = 9,
  adoption = 10
}

export enum ProjectStakeholderField {
  isKeyStakeholder = 1,
  influence = 2,
  impact = 3,
  commitment = 4,
  receptiveness = 5,
  sentiment = 6
}

export enum AudienceStakeholderProfilingField {
  awareness = 1,
  understanding = 2,
  commitment = 3,
  capability = 4,
  adoption = 5
}

export enum PulseField {
  NAME = 1,
  DESCRIPTION = 2,
  START_DATE = 3,
  END_DATE = 4,
  awareness = 5,
  understanding = 6,
  commitment = 7,
  capability = 8,
  adoption = 9
}
