import { ValueFormatterParams } from "ag-grid-community";
import { CircleStatus, CircleStatusColours } from "../../../../../../components/ui/CircleStatus";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { BaseGridColumnBuilder } from "./BaseGridColumnBuilder";
import { AudienceStakeholderProfilingField, PhaseField, PulseField } from "../../utils/GridFields";

export abstract class AuccaGridColumnBuilder extends BaseGridColumnBuilder {
  buildAudienceProfilingColumn = (field: string, headerName: string) => {
    let model = new SimpleTextColumnBuilder({
      field: field,
      headerName: headerName,
      valueGetter: params => {
        const val = params.data[field];
        if (!val) return "";
        return `${val.toFixed(2)} - ${GetLabelByValue(val)}`;
      },
      cellRenderer: e => {
        let value = e.data[field];
        if (!value) value = 0;
        return (
          <>
            <CircleStatus colour={GetColour(value)} className="mr-2" /> {value.toFixed(2)} - {GetLabelByValue(value)}
          </>
        );
      }
    })
      .makeEditable(false)
      .makeReadOnly(true)
      .setFilterOptions(BASE_FILTER_CONFIG);
    return model.generateColumnOptions();
  };

  buildEditableAudienceProfilingColumn = (
    field: string,
    headerName: string,
    canEdit: boolean,
    audienceStakeholderProfilingField: AudienceStakeholderProfilingField | PhaseField | PulseField,
    updateProfiling?: any
  ) => {
    let model = new SimpleTextColumnBuilder({
      field: field,
      headerName: headerName
    })
      .makeEditable(canEdit && typeof updateProfiling === "function")
      .makeReadOnly(!canEdit || typeof updateProfiling !== "function")
      .setCellDataType("number")
      .setColumnOptions({
        valueFormatter: (params: ValueFormatterParams<any>) => {
          if (!params.value || params.value === 0) {
            return "";
          }
          return params.value;
        }
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit && typeof updateProfiling === "function") {
      model.setColumnOptions({
        valueSetter: params => {
          updateProfiling(audienceStakeholderProfilingField, params.data.id, params.newValue);
          return true;
        }
      });
    }
    return model.generateColumnOptions();
  };
}

const GetLabelByValue = value => {
  if (value === 0) {
    return "Unknown";
  } else if (value < 2.5) {
    return "Cautious";
  } else if (value >= 2.5 && value <= 3.5) {
    return "Neutral";
  } else {
    return "Advocate";
  }
};

const GetColour = value => {
  if (value <= 0) {
    return CircleStatusColours.GREY;
  } else if (value > 0 && value < 2.5) {
    return CircleStatusColours.RED;
  } else if (value >= 2.5 && value <= 3.5) {
    return CircleStatusColours.BLUE;
  } else {
    return CircleStatusColours.GREEN;
  }
};
