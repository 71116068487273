import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import { StakeholderType } from "../../../../enums";
import http from "../Http";
import { ProjectStakeholderField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";

export class ProjectStakeholdersApi extends ExportableProjectApi<FP.Entities.IProjectStakeholder> {
  controller: string = "stakeholders";

  constructor(http: AxiosInstance) {
    super(http, "stakeholders");
  }

  //#region CRUD

  async getStakeholdersOnly(organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/thin`;
    let res = await this.http.get(url);
    return res.data;
  }

  async getStakeholderById(
    organisationId: number,
    projectId: number,
    stakeholderId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/${stakeholderId}`;
    let res = await this.http.get(url);
    return res.data;
  }

  async getDetailedStakeholderByProjectStakeholderId(
    organisationId: number,
    projectId: number,
    projectStakeholderId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/${projectStakeholderId}/detailed`;
    let res = await this.http.get(url);
    return res.data;
  }

  async getDetailedById(
    organisationId: number,
    projectId: number,
    projectStakeholderId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectStakeholderId}/detailed`;
    let res = await this.http.get(url);
    return res.data;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number,
    projectId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  assignMultipleImpacts = async (
    organisationId: number,
    projectId: number,
    stakeholderIds: number[],
    impactIds: number[]
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/add-impact-range`;
    let res = await this.http.post(url, { stakeholderIds, impactIds });
    return res.data;
  };

  addStakeholderRange = async (organisationId: number, projectId: number, stakeholderIds: number[]) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/add-stakeholder-range`;
    let res = await this.http.post(url, { stakeholderIds });
    return res.data;
  };

  async getDeletedStakeholder(
    organisationId: number,
    projectId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/${stakeholderId}/deleted`;
    let res = await this.http.get(url, config);
    return res.data;
  }

  deleteRange = async (
    organisationId: number,
    projectId: number,
    projectStakeholderIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/delete-range`;
    let res = await this.http.post(url, projectStakeholderIds, config);
    return res.data;
  };

  reviewRange = async (
    organisationId: number,
    projectId: number,
    projectStakeholderIds: number[],
    comment: string,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/review-range`;
    let res = await this.http.post(url, { projectStakeholderIds, comment }, config);
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    projectStakeholderField: ProjectStakeholderField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectStakeholderId}/${projectStakeholderField}`,
      data,
      config
    );
    return res.data;
  };

  deleteField = async (
    organisationId: number,
    projectId: number,
    stakeholderId: number,
    stakeholderField: ProjectStakeholderField,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${stakeholderId}/${stakeholderField}`,
      config
    );
    return res.data;
  };

  bulkUpdateStakeholders = async (
    organisationId: number,
    projectId: number,
    projectStakeholderIds: number[],
    fields: any,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/bulk-update-project-stakeholders`;
    let res = await this.http.post(url, { projectStakeholderIds, ...fields }, config);
    return res.data;
  };
  //#endregion CRUD

  //#region Impact
  addImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/impacts/${projectStakeholderId}`;
    let res = await this.http.post(url, impactIds, config);
    return res.data;
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/impacts/${projectStakeholderId}/${impactId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  getUnassignedImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/unassignedImpacts/${projectStakeholderId}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getStakeholderAndAudienceCountsForProject = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderAudienceCounts>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/counts`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getUnassignedProjectStakeholders = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/unassignedStakeholders`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectStakeholderImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/impacts/${projectStakeholderId}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getStakeholderOwnersInProject = async (
    organisationId: number,
    projectId: number,
    stakeholderType: StakeholderType,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/stakeholder-owners/${stakeholderType}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion
}

const instance = new ProjectStakeholdersApi(http);
export default instance;
