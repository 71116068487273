import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { AudienceStakeholderProfilingField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";

export class PulseAudienceStakeholdersApi extends FilteredProjectApiModel<FP.Entities.IPulseAudienceStakeholder> {
  controller: string = "pulse-audience-stakeholders";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "pulse-audience-stakeholders");
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    id: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: AudienceStakeholderProfilingField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/${field}`,
      data,
      config
    );
    return res.data;
  };
}

const instance = new PulseAudienceStakeholdersApi(http);
export default instance;
