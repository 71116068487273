import { ColDef, ICellEditorParams } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { minimumDate, UiSizes } from "../../../../../../enums";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import {
  AuccaColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  PhaseColDefFieldNamesEnum,
  PulseColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { PulseField } from "../../utils/GridFields";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import moment from "moment";
import { stringToMomentDateForComparison } from "../../utils/helpers";
import { laterDateComparator } from "../../../../GridHelpers";
import { AuccaGridColumnBuilder } from "../base/AuccaGridColumnBuilder";
import PulsesApi from "../../../../../../services/api/v2/pulses/Pulses.api";
import { MasterDetailRowColumnBuilder } from "../../columns/commonColumns/MasterDetailRowColumn/MasterDetailRowColumn_builder";
import { SyntheticEvent } from "react";
import { ButtonIcon, ButtonTypes } from "../../../../../../components/ui/Button";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";

export interface PulsesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewPhases: boolean;
  columns: string[];
  onFieldUpdate: () => void;
  showAudiences: boolean;
}

export class PulsesGridColumnBuilder extends AuccaGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PulsesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  onFieldUpdate: () => void;
  showAudiences: boolean;

  constructor(gridProps: PulsesGridColumnBuilderProps) {
    super(PulsesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.showAudiences = gridProps.showAudiences;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [AuccaColDefFieldNamesEnum.Awareness]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Awareness,
          header || AuccaColDefFieldNamesEnum.Awareness
        ),
      [AuccaColDefFieldNamesEnum.Understanding]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Understanding,
          header || AuccaColDefFieldNamesEnum.Understanding
        ),
      [AuccaColDefFieldNamesEnum.Commitment]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Commitment,
          header || AuccaColDefFieldNamesEnum.Commitment
        ),
      [AuccaColDefFieldNamesEnum.Capability]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Capability,
          header || AuccaColDefFieldNamesEnum.Capability
        ),
      [AuccaColDefFieldNamesEnum.Adoption]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Adoption,
          header || AuccaColDefFieldNamesEnum.Adoption
        ),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [PulseColDefFieldNamesEnum.Audiences]: () =>
        new MasterDetailRowColumnBuilder({
          field: PulseColDefFieldNamesEnum.Audiences,
          headerName: I18n.t("phrases.audiences"),
          pinned: "right",
          resizable: false,
          width: 70
        })
          .makeEditable(false)
          .makeReadOnly(true)
          .makeSelectable(false)
          .disableHeaderActions()
          .useExpandedMasterHeaderHighlighting([PulseColDefFieldNamesEnum.Audiences])
          .withCustomActionsCellRenderer(PulseColDefFieldNamesEnum.Audiences, this.generateViewAudiencesAction)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    if (this.showAudiences) {
      res.push(this.columnDefs[PulseColDefFieldNamesEnum.Audiences]());
    }

    if (!this.showAudiences) {
      res.unshift(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());
    }

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit && !this.gridProps.showAudiences)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit && !this.gridProps.showAudiences) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit && !this.gridProps.showAudiences) {
      model.makeDeletable().setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.StartDate,
          headerName: I18n.t("grids.startDate")
        })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable((params: ICellEditorParams) => {
        if (
          params.data[PulseColDefFieldNamesEnum.Phase].startDate &&
          moment(params.data[PulseColDefFieldNamesEnum.Phase].startDate) > moment(minimumDate)
        ) {
          return this.gridProps.canEdit;
        }
        return false;
      })
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: (params: ICellEditorParams) => {
            let phaseStartDate = moment(
              params.data[PulseColDefFieldNamesEnum.Phase][CommonColDefFieldNamesEnum.StartDate]
            );
            if (moment(params.data[CommonColDefFieldNamesEnum.StartDate]) > moment(minimumDate)) {
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            return phaseStartDate;
          },
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = minimumDate;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            const phasePulses = params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Pulses];
            const startDate = moment(e);

            const phase = params.data[PulseColDefFieldNamesEnum.Phase];

            const isStartDateValid =
              startDate.isBetween(
                phase[CommonColDefFieldNamesEnum.StartDate],
                phase[CommonColDefFieldNamesEnum.EndDate],
                null,
                "[)"
              ) &&
              !phasePulses.some(
                pulse =>
                  stringToMomentDateForComparison(pulse.startDate) ===
                  stringToMomentDateForComparison(startDate.toString())
              );

            if (isStartDateValid) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.EndDate, headerName: I18n.t("grids.endDate") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.EndDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.EndDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.EndDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: params => {
            if (
              params.data[CommonColDefFieldNamesEnum.StartDate] > minimumDate &&
              params.data[CommonColDefFieldNamesEnum.EndDate] > minimumDate &&
              !!params.data[CommonColDefFieldNamesEnum.EndDate]
            ) {
              return moment(params.data[CommonColDefFieldNamesEnum.StartDate]).add(1, "day");
            }

            if (params.data[CommonColDefFieldNamesEnum.EndDate] > minimumDate) {
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            return params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Project][
              CommonColDefFieldNamesEnum.ActualEndDate
            ];
          },
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = minimumDate;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let projectEndDate = moment(
              params.data[PulseColDefFieldNamesEnum.Phase][PhaseColDefFieldNamesEnum.Project][
                CommonColDefFieldNamesEnum.ActualEndDate
              ]
            );

            let endDate = moment(e);
            let startDate =
              params.data[CommonColDefFieldNamesEnum.StartDate] &&
              params.data[CommonColDefFieldNamesEnum.StartDate] > moment(minimumDate) &&
              moment(params.data[CommonColDefFieldNamesEnum.StartDate]);

            if (
              !(endDate === startDate) &&
              endDate <= projectEndDate &&
              (!startDate || laterDateComparator(endDate.toDate(), startDate.toDate()) === true)
            ) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  generateViewAudiencesAction = (isExpanded: boolean, classname: string, onclick: (ev: SyntheticEvent) => void) => {
    return (
      <>
        <ButtonIcon
          className={`mt-2 mx-1 pointer-class ${classname}-view`}
          type={isExpanded ? ButtonTypes.DANGER : ButtonTypes.OUTLINE_SECONDARY}
          symbol={isExpanded ? IconSymbols.Close : IconSymbols.Eye}
          size={UiSizes.XXS}
          isDisabled={!this.gridProps.userCanViewPhases}
          onClick={onclick}
        >
          {!isExpanded && I18n.t("phrases.viewAudiences")}
        </ButtonIcon>
      </>
    );
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(PulseField.DESCRIPTION, entityId, text);
    this.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(PulseField.NAME, entityId, text);
    this.onFieldUpdate();
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PulseField.START_DATE, entityId, date);
    this.onFieldUpdate();
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PulseField.END_DATE, entityId, date);
    this.onFieldUpdate();
  };
}
