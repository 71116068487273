import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BenefitField } from "../../../../enums";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class AudiencesApi extends FilteredProjectApiModel<FP.Entities.IAudience> {
  controller: string = "audiences";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "audiences");
  }

  getAudiences = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/projects/${projectId}/${this.controller}`, config);
    return res.data;
  };

  getGridData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAudienceSummary[]>> => {
    let res: AxiosResponse<IFlightPathApiResponse<FP.Entities.IAudienceSummary[]>, any> = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/grid-data`,
      config
    );

    res.data.payload = res.data.payload.map(e => {
      return {
        ...e,
        impacts: JSON.parse(e.impacts),
        actions: JSON.parse(e.actions),
        impactActions: JSON.parse(e.impactActions)
      };
    });

    return res.data;
  };

  getAudienceProjectsWithImpacts = async (organisationId: number, audienceId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/0/${this.controller}/${audienceId}/assigned-projects-with-impacts`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  getAudienceProjectsWithActions = async (organisationId: number, audienceId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/0/${this.controller}/${audienceId}/assigned-projects-with-actions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    benefitId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    benefitField: BenefitField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/${benefitField}`,
      data,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      { audienceIds: ids },
      config
    );
    return res.data;
  };

  addStakeholdersRange = async (
    organisationId: number,
    projectId: number,
    audienceId: number,
    stakeholderIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${audienceId}/add-stakeholder-range`,
      { stakeholderIds: stakeholderIds },
      config
    );
    return res.data;
  };

  getUnassignedStakeholders = async (
    organisationId: number,
    projectId: number,
    audienceId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${audienceId}/unassigned-stakeholders`,
      config
    );
    return res.data;
  };

  getStakeholders = async (
    organisationId: number,
    projectId: number,
    audienceId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${audienceId}/stakeholders`,
      config
    );
    return res.data;
  };

  getPulseStakeholders = async (
    organisationId: number,
    projectId: number,
    pulseId: number,
    audienceId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${audienceId}/pulses/${pulseId}/stakeholders`,
      config
    );
    return res.data;
  };

  getStakeholdersFromAudiencesIds = async (
    organisationId: number,
    projectId: number,
    audienceIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/stakeholders-by-audienceIds`,
      { audienceIds },
      config
    );
    return res.data;
  };

  addBulkActionsToAudiences = async (
    organisationId: number,
    projectId: number,
    audienceIds: number[],
    actionIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-actions-to-audiences`;
    let res = await this.http.post(url, { audienceIds, actionIds }, config);
    return res.data;
  };
  addBulkImpactsToAudiences = async (
    organisationId: number,
    projectId: number,
    audienceIds: number[],
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-impacts-to-audiences`;
    let res = await this.http.post(url, { audienceIds, impactIds }, config);
    return res.data;
  };

  addBulkAudiencesToActions = async (
    organisationId: number,
    projectId: number,
    audienceIds: number[],
    actionIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-audiences-to-actions`;
    let res = await this.http.post(url, { audienceIds, actionIds }, config);
    return res.data;
  };
  addBulkAudiencesToImpacts = async (
    organisationId: number,
    projectId: number,
    audienceIds: number[],
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-audiences-to-impacts`;
    let res = await this.http.post(url, { audienceIds, impactIds }, config);
    return res.data;
  };
}

const instance = new AudiencesApi(http);
export default instance;
