import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../util/BaseModel";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";
import AudienceStakeholdersApi, {
  AudienceStakeholdersApi as IAudienceStakeholdersApi
} from "../../../../../../../../services/api/v2/audenceStakeholders/AudienceStakeholders.api";

export class AudienceStakeholderTagsSidebarModel extends BaseModel {
  audienceStakeholdersProvider: IAudienceStakeholdersApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref audienceStakeholder: FP.Entities.IAudienceStakeholder;
  organisationId: number;
  projectId: number;
  audienceStakeholderId: number;

  constructor(organisationId: number, projectId: number, audienceStakeholderId: number) {
    super();
    makeObservable(this);
    this.audienceStakeholdersProvider = AudienceStakeholdersApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audienceStakeholderId = audienceStakeholderId;
  }

  onMount = async () => {
    this.setIsLoading(true);
    await this.loadAudienceStakeholder(this.audienceStakeholderId);
  };

  @action
  setAudienceStakeholder = (audienceStakeholder: FP.Entities.IAudienceStakeholder) => {
    this.audienceStakeholder = audienceStakeholder;
    this.setIsLoading(false);
  };

  @action
  loadAudienceStakeholder = async (audienceStakeholderId: number) => {
    let res = await this.audienceStakeholdersProvider.getDetailedById(
      this.organisationId,
      this.projectId,
      audienceStakeholderId
    );

    if (!res || res.isError) return;
    this.setAudienceStakeholder(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    let res = await this.tagsProvider.removeAudienceStakeholderTagAssoc(
      this.organisationId,
      this.projectId,
      tagId,
      this.audienceStakeholder.id
    );
    if (!res || res.isError) return;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
