import { action, makeObservable, observable } from "mobx";
import * as React from "react";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ProjectProcesssApi, {
  ProjectProcessesApi as IProjectProcesssApi
} from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { IModalContextModel } from "../../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";

export class ProjectProcessImpactsSidebarModel extends BaseModel {
  projectProcessesProvider: IProjectProcesssApi;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref projectProcessImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredProjectProcessImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;
  projectProcessId: number;

  constructor(organisationId: number, projectId: number, projectProcessId: number) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.projectProcessesProvider = ProjectProcesssApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
    this.projectProcessId = projectProcessId;
    this.loadProjectProcess(projectProcessId);
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setProjectProcess = (impacts: FP.Entities.IImpact[]) => {
    this.projectProcessImpacts = impacts;
    this.filteredProjectProcessImpacts = impacts;
    this.isLoading = false;
  };

  @action
  loadProjectProcess = async (projectProcessId: number) => {
    this.isLoading = true;
    let res = await this.projectProcessesProvider.getImpactByProjectProcessId(
      this.organisationId,
      this.projectId,
      projectProcessId
    );
    if (!res || res.isError) return;

    this.setProjectProcess(res.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterProjectProcessImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterProjectProcessImpacts();
    this.hideSearchMode();
  };

  @action
  filterProjectProcessImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredProjectProcessImpacts = this.projectProcessImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredProjectProcessImpacts = this.projectProcessImpacts;
    }
  };

  deleteImpactProjectProcess = async (impactId: number) => {
    let res = await this.projectProcessesProvider.deleteImpact(
      this.organisationId,
      this.projectId,
      this.projectProcessId,
      impactId
    );
    if (res.isError) return;

    await this.loadProjectProcess(this.projectProcessId);
  };
}
