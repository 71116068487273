import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { action, makeObservable, observable } from "mobx";
import ToasterService, { IToasterService } from "../../../../../../../core/toaster/ToasterService";
import { ILocalStorageService } from "../../../../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../../../../services/local/localStorageService/LocalStorageService";
import { IModalContextModel } from "../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import PhasesApi, { PhasesApi as IPhasesApi } from "../../../../../../../services/api/v2/phases/Phases.api";
import { IProgressIndicatorModel } from "../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProgressIndicatorModel from "../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../../../services/api/v2/projects/Projects.api";
import { SingleFormModel } from "../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";

export class WrapperPhasesGridWithDataModel extends BaseModel {
  formModel: SingleFormModel;
  organisationId: number;
  modalService: IModalContextModel;
  phasesProvider: IPhasesApi;
  projectsProvider: IProjectsApi;
  localStorageService: ILocalStorageService;
  toasterService: IToasterService;
  teamUsers;
  httpProgress: IProgressIndicatorModel;
  @observable projectId: number;
  @observable isLoading: boolean = true;
  @observable.ref projectPhases: FP.Entities.IPhase[];
  @observable project: FP.Entities.IProject;
  user: FP.Entities.IUser;

  constructor(organisationId: number, projectId: number, user: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.user = user;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.phasesProvider = PhasesApi;
    this.projectsProvider = ProjectsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.localStorageService = LocalStorageService;
    this.toasterService = ToasterService;
    this.formModel = new SingleFormModel();
  }

  onMount = async () => {
    await this.loadProject();
    await this.loadProjectPhases();
  };

  @action
  loadProject = async () => {
    this.isLoading = true;
    let res = await this.projectsProvider.getById(this.organisationId, this.projectId);

    if (!res || res.isError) {
      return;
    }

    this.setProject(res.payload);
  };

  @action
  loadProjectPhases = async () => {
    this.isLoading = true;
    let res = await this.phasesProvider.getGridData(this.organisationId, this.projectId);

    if (!res || res.isError) {
      return;
    }

    this.setProjectPhases(res.payload);
    this.setIsLoading(false);
  };

  @action
  setProjectPhases = (projectPhases: FP.Entities.IPhase[]) => {
    this.projectPhases = projectPhases;
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
