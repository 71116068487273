import { ColDef } from "ag-grid-community";
import _ from "lodash";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { AudienceField, Enums, Translator } from "../../../../../../enums";
import { AudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { zeroToTenTextMatcher } from "../../../../filters/TextMatcher";
import {
  AuccaColDefFieldNamesEnum,
  AudienceColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { AuccaGridColumnBuilder } from "../base/AuccaGridColumnBuilder";

export interface PulseAudiencesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewAudiences: boolean;
  columns: FP.Entities.IColumnDef[] | string[];
  audiencesProvider: AudiencesApi;
}

export class PulseAudiencesGridColumnBuilder extends AuccaGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PulseAudiencesGridColumnBuilderProps;
  columnDefs: Dictionary<(header?: string) => ColDef>;
  organisationId: number;
  projectId: number;
  levelClassRules: any;

  constructor(gridProps: PulseAudiencesGridColumnBuilderProps) {
    super(() => {}, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.levelClassRules = {
      "stakeholder-grid__cell--unknown": params =>
        params.value <= 0 || typeof params.value === "undefined" || params.value === null,
      "stakeholder-grid__cell--low": params => params.value >= 1 && params.value <= 4,
      "stakeholder-grid__cell--medium": params => params.value >= 5 && params.value <= 7,
      "stakeholder-grid__cell--high": params => params.value > 7
    };

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Name]: (header?: string) => this.buildNameColumn(header),
      [AudienceColDefFieldNamesEnum.Impact]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Impact,
          AudienceField.impact,
          `${header || I18n.t("grids.impact")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.ImpactLevelMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.ImpactLevel(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.ImpactLevelMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Size]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Size,
          AudienceField.size,
          `${header || I18n.t("grids.size")}`,
          this.gridProps.canEdit,
          Translator.SizeMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.Size(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.SizeMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Influence]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Influence,
          AudienceField.influence,
          `${header || I18n.t("grids.influence")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.InfluenceLevelMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            valueFormatter: params => {
              const influence = Translator.InfluenceLevelMapped().find(e => e.key === params.value + "");
              return influence ? influence.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Count]: (header?: string) =>
        new NameColumnBuilder({
          field: AudienceColDefFieldNamesEnum.Count,
          headerName: header || I18n.t("grids.count"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [AuccaColDefFieldNamesEnum.Awareness]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Awareness,
          header || AuccaColDefFieldNamesEnum.Awareness
        ),
      [AuccaColDefFieldNamesEnum.Understanding]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Understanding,
          header || AuccaColDefFieldNamesEnum.Understanding
        ),
      [AuccaColDefFieldNamesEnum.Commitment]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Commitment,
          header || AuccaColDefFieldNamesEnum.Commitment
        ),
      [AuccaColDefFieldNamesEnum.Capability]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Capability,
          header || AuccaColDefFieldNamesEnum.Capability
        ),
      [AuccaColDefFieldNamesEnum.Adoption]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Adoption,
          header || AuccaColDefFieldNamesEnum.Adoption
        )
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };
  buildNameColumn = (header?: string) => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: header || "Name", cellRenderer: "agGroupCellRenderer" }))
      .makeSelectable(false)
      .makeEditable(false)
      .setFilterOptions(NAME_FILTER_CONFIG);

    return model.generateColumnOptions();
  };
}
