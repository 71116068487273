import { ColDef, GridApi, IRowNode, GridReadyEvent, IDetailCellRendererParams, GridOptions } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, GridTypes } from "../../../../../../enums";
import { AuccaColDefFieldNamesEnum, CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { PulsesGridColumnBuilder } from "./PulsesGridView_columns";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { GetPulsesGridActions } from "./PulsesGridView_actions";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { SHOW_PULSE_DELETE_CONFIRM_MODAL } from "./PulsesGridView_modals";
import { PulseField } from "../../utils/GridFields";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import PulsesApi, { PulsesApi as IPulsesApi } from "../../../../../../services/api/v2/pulses/Pulses.api";

export class PulsesGridModel {
  organisationId: number;
  projectId: number;
  phaseId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  userCanViewPhases: boolean;
  userCanEditPhases: boolean;
  phasesGridColumnBuilder: PulsesGridColumnBuilder;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  httpProgress: IProgressIndicatorModel;
  pulseProvider: IPulsesApi;
  refreshDataHandler: any;
  gridModalBuilder: GridModalBuilder;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  gridApi: GridApi;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;
  gridToolbarType: AppGridToolbarType;
  @observable preSelectedItemIds: number[];
  gridType: EntityTypes | GridTypes;
  urlSearchParams: URLSearchParams;
  microPhaseForm: SingleFormModel;
  toasterService: IToasterService;
  gridToastService: IGridToastService;
  detailGridColumns: ColDef<any, any>[];
  detailCellRendererParams: IDetailCellRendererParams<any, any>;
  @observable gridOptions: GridOptions;
  showAudiences: boolean;

  constructor(
    organisationId: number,
    projectId: number,
    phaseId: number,
    authUser: FP.Entities.IUser,
    refreshDataHandler: any,
    onFieldUpdate: () => void,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes,
    urlSearchParams: URLSearchParams,
    showAudiences: boolean
  ) {
    makeObservable(this);
    this.userCanViewPhases = PermissionsContext.canViewField(PermissionFields.PROJECTS, organisationId, projectId);
    this.userCanEditPhases = PermissionsContext.canEditField(PermissionFields.PROJECTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.phaseId = phaseId;
    this.authUser = authUser;
    this.httpProgress = ProgressIndicatorModel;
    this.pulseProvider = PulsesApi;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
    this.showAudiences = showAudiences;
    this.toasterService = ToasterService;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.gridToolbarType = gridToolbarType;
    this.preSelectedItemIds = preSelectedItemIds;
    this.gridType = gridType;
    this.urlSearchParams = urlSearchParams;
    this.generateAddons();
  }

  @action
  onMount = () => {
    this.generateFn();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  @action
  generateGridConfig = () => {
    this.phasesGridColumnBuilder = new PulsesGridColumnBuilder({
      canEdit: this.userCanEditPhases && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewPhases: this.userCanViewPhases,
      onFieldUpdate: this.onFieldUpdate,
      showAudiences: this.showAudiences,
      columns: [
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        CommonColDefFieldNamesEnum.StartDate,
        CommonColDefFieldNamesEnum.EndDate,
        AuccaColDefFieldNamesEnum.Awareness,
        AuccaColDefFieldNamesEnum.Understanding,
        AuccaColDefFieldNamesEnum.Commitment,
        AuccaColDefFieldNamesEnum.Capability,
        AuccaColDefFieldNamesEnum.Adoption,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.phasesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetPulsesGridActions(this));
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, this.gridType ?? EntityTypes.PHASES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.PHASES,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: this.gridType ?? EntityTypes.PHASES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  setGridOptions = (gridOptions: GridOptions) => {
    this.gridOptions = gridOptions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  onFirstDataRendered = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.preSelectedItemIds?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  deleteFieldData = async (pulseId: number, pulseField: PulseField) => {
    const res = await this.pulseProvider.deleteGridField(this.organisationId, this.phaseId, pulseId, pulseField);
    if (res.isError) return false;
    if (this.refreshDataHandler) {
      await this.refreshDataHandler();
    }
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  showPulseConfirmDeleteModal = () => {
    return SHOW_PULSE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.pulseProvider
      .deleteRange(this.organisationId, this.phaseId, itemIds)
      .then(e => this.refreshDataHandler && this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };
}
