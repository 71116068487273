import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { AudienceStakeholderProfilingField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";

export class AudienceStakeholdersApi extends FilteredProjectApiModel<FP.Entities.IAudience> {
  controller: string = "audience-stakeholders";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "audience-stakeholders");
  }

  async getDetailedById(
    organisationId: number,
    projectId: number,
    id: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IAudienceStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/detailed`;
    let res = await this.http.get(url);
    return res.data;
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    id: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: AudienceStakeholderProfilingField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/${field}`,
      data,
      config
    );
    return res.data;
  };

  getColumnDefs = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IColumnDef[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/column-defs`,
      config
    );
    return res.data;
  };
}

const instance = new AudienceStakeholdersApi(http);
export default instance;
