import React from "react";
import { observer } from "mobx-react";
import { WrapperPhaseGridWithData } from "../../../../../core/grids/builder/directorBuilder/grids/PhasesGrid/WrapperPhasesGridWithData/WrapperPhasesGridWithData_view";

export interface ProjectPhaseSettingsProps {}

const ProjectPhaseSettings: React.FunctionComponent<ProjectPhaseSettingsProps> = observer(() => {
  return <WrapperPhaseGridWithData></WrapperPhaseGridWithData>;
});

export { ProjectPhaseSettings };
