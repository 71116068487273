import { observer } from "mobx-react-lite";
import { WrapperPhaseGridWithData } from "../../../../../core/grids/builder/directorBuilder/grids/PhasesGrid/WrapperPhasesGridWithData/WrapperPhasesGridWithData_view";

export const ReadinessTrackingView: React.FC = observer(() => {
  return (
    <>
      <WrapperPhaseGridWithData showAudiences={true}></WrapperPhaseGridWithData>
    </>
  );
});
